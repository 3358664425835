import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faHome, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Button,
  Dropdown,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Table,
} from "@themesberg/react-bootstrap";
import AsyncSelect from "react-select/async";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";

import services from "../../services/index";

export default () => {
  let history = useHistory();
  const selectStudentRef = useRef();
  const selectCourseRef = useRef();

  function handleNav(path) {
    history.push(path);
  }

  const [loading, setLoading] = useState(false);
  const [awardDate, setAwardDate] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [moduleUnits, setModuleUnits] = useState([]);
  const [totalECTS, setTotalECTS] = useState(0);
  const [saveValid, setSaveValid] = useState(false);
  const [selectedCertificateData, setSelectedCertificateData] = useState(null);

  let centerOptions = [];

  const filterSelectionData = (inputValue, data) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseCentreOptions = async (inputValue) => {
    try {
      let params = { limit: 10, page: 1, search: inputValue };
      const req = await services.centreService.getCentres(params);
      let resData = req?.data?.data ? req.data.data : [];
      centerOptions = [];
      resData.forEach((element) => {
        centerOptions.push({ value: element.ID, label: element.name });
      });
      return filterSelectionData(inputValue, centerOptions);
    } catch (e) {
      return [];
    }
  };

  let promiseStudentOptions = async (inputValue) => {
    try {
      if (selectedCentre) {
        let params = {
          limit: 10,
          page: 1,
          search: inputValue,
          centre_id: selectedCentre,
        };
        const req = await services.studentService.getStudentsByCentreID(params);
        let resData = req?.data?.data ? req.data.data : [];

        let options = [];
        resData.forEach((element) => {
          options.push({
            value: element.ID,
            label:
              element.learner_id +
              " - " +
              element.first_name +
              " " +
              element.last_name,
            courses: element.courses,
          });
        });
        return filterSelectionData(inputValue, options);
      }

      return null;
    } catch (e) {
      return null;
    }
  };

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "centre":
        selectStudentRef.current.clearValue();
        setSelectedCentre(e && e.value ? e.value : "");
        setSelectedCourse(0);
        break;
      case "student":
        setSelectedStudent(e && e.value ? e.value : "");
        setCourseOptions(e && e.courses ? e.courses : []);
        setSelectedCourse(0);
        setModuleUnits([]);
        break;

      case "course":
        let selectedCourseID = e && e.target?.value ? e.target.value : 0;
        const result = courseOptions.find(
          (course) => course.ID == selectedCourseID
        );

        if (result) {
          result.moduleunits.forEach((element) => {
            element.ects = 0;
            element.grade = "PASS";
          });
          console.log(result?.moduleunits);
          setModuleUnits(result.moduleunits);
          setSelectedCourse(selectedCourseID);
        } else {
          setSelectedCourse(0);
          setModuleUnits([]);
        }
        break;

      case "award_date":
        let date = e && e.target?.value ? e.target.value : 0;
        console.log(e);
        setAwardDate(e._d);
        console.log(awardDate);
        break;

      case "certificate_number":
        let certificate_number = e && e.target?.value ? e.target.value : 0;
        setCertificateNumber(certificate_number);
        break;

      default:
        break;
    }
  }

  const details_handler = (event, index) => {
    const items = [...moduleUnits];
    items[index][event.target.name] = event.target.value;
    console.log(items);
    setModuleUnits(items);
    calTotalECTS();
  };

  function validateForm() {
    let formsValid = false;
    formsValid =
      certificateNumber &&
      selectedCourse &&
      selectedStudent &&
      selectedCentre &&
      awardDate !== "" &&
      totalECTS !== 0
        ? true
        : false;
    setSaveValid(formsValid);
    return formsValid;
  }

  async function saveCertificate() {
    try {
      let status = validateForm();
      var momentDate = new moment(awardDate);
      var awardedDateFormated = momentDate.format("YYYY-MM-DD HH:mm:ss");
      const awardedDate = new Date(awardedDateFormated);
      let unitMarksData = [];
      moduleUnits.forEach((element) => {
        unitMarksData.push({
          student_id: selectedStudent,
          certificate_id: 0,
          course_id: parseInt(selectedCourse),
          moduleunit_id: element.ID,
          ects: parseFloat(element.ects),
          grade: element.grade,
          status: 1,
        });
      });

      let data = {
        certificate: {
          awarded_date: awardedDate,
          certificate_number: parseInt(certificateNumber),
          student_id: selectedStudent,
          country_id: 1,
          centre_id: selectedCentre,
          course_id: parseInt(selectedCourse),
          status: 1,
        },
        unitmarks: unitMarksData,
      };

      const req = await services.certificateService.postCertificateWithMarks(
        data
      );
      toast.success("Certificate Created");
      history.push('/certificate-management');
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  }

  useEffect(() => {
    (async () => {
      // fetchData({ limit: 10, page: 1 });
      calTotalECTS();
      validateForm();
    })();
  }, [
    totalECTS,
    moduleUnits,
    selectedCentre,
    selectedCourse,
    selectedStudent,
    courseOptions,
    awardDate,
    certificateNumber,
  ]);

  async function fetchData(params) {
    setLoading(true);
    const req = await services.certificateService.getCertificates(params);
    // SetTableOptions(req?.data ? req.data : {});
    setLoading(false);
  }

  function calTotalECTS() {
    let total = 0.0;
    if (moduleUnits.length > 0) {
      let allFilled = true;
      for (let indexETC = 0; indexETC < moduleUnits.length; indexETC++) {
        const elementETC = moduleUnits[indexETC];
        allFilled = elementETC.ects ? true : false;
        total = (parseFloat(total) + parseFloat(elementETC.ects)).toFixed(1);
        console.log(total);
        if (allFilled) {
          setTotalECTS(total);
        }
      }
    } else {
      setTotalECTS(0);
    }
  }

  async function fetchCertificate(id) {
    const req = await services.certificateService.getCertificate(id);

    if (req?.data?.data?.certificate?.awarded_date) {
      var awardedDate = new Date(req.data.data.certificate.awarded_date);
      const awardedDateFormated = dateFormat(awardedDate, "dS mmmm yyyy");
      req.data.data.certificate.awardedDateFormated = awardedDateFormated;

      let totCredits = 0;
      let totECTS = 0;
      let unitMarksArray = req.data.data.unitmarks;
      unitMarksArray.forEach((element) => {
        totCredits += element.ects && element.moduleunit?.credits
          ? element.moduleunit.credits
          : 0;
        totECTS += element.ects ? element.ects : 0;
      });

      req.data.data.certificate.total_credits = totCredits;
      req.data.data.certificate.total_ects = totECTS;
    }

    setSelectedCertificateData(req?.data?.data ? req.data.data : null);
  }

  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleNav("/certificate-management")}
            >
              Certificate Management
            </Breadcrumb.Item>
            <Breadcrumb.Item active>New Certificate</Breadcrumb.Item>
          </Breadcrumb>
          <h4>New Certificate</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="certificateNumber">
                      <Form.Label>Certificate Number</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>C</InputGroup.Text>
                        <Form.Control
                          type="number"
                          placeholder="Number"
                          onChange={(e) =>
                            handleChange(e, "certificate_number")
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="birthday">
                      <Form.Label>Award Date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => handleChange(e, "award_date")}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                awardDate
                                  ? moment(awardDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              placeholder="DD/MM/YYYY"
                              readOnly={true}
                              onFocus={openCalendar}
                              onChange={(e) => handleChange(e, "award_date")}
                            />
                          </InputGroup>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Centre</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseCentreOptions}
                        onChange={(e) => handleChange(e, "centre")}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Student</Form.Label>
                      <AsyncSelect
                        ref={selectStudentRef}
                        cacheOptions={false}
                        loadOptions={promiseStudentOptions}
                        isClearable={true}
                        isDisabled={selectedCentre ? false : true}
                        onChange={(e) => handleChange(e, "student")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Course</Form.Label>
                      <Form.Select
                        disabled={courseOptions.length === 0}
                        id="state"
                        defaultValue="0"
                        onChange={(e) => handleChange(e, "course")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {courseOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <h5 className="my-4">Unit Marks</h5>
                <Row>
                  <Col sm={12} className="mb-3">
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">Unit Ref</th>
                          <th className="border-0">Unit Title</th>
                          <th className="border-0">Credits</th>
                          <th className="border-0">ETCS</th>
                          <th className="border-0">Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {moduleUnits.map((r, index) => {
                          return (
                            <tr key={index}>
                              <td className="fw-bold border-0">
                                {r.unitref ? r.unitref : "-"}
                              </td>
                              <td className="border-0">
                                {r.title ? r.title : "-"}
                              </td>
                              <td className="border-0">
                                {r.credits ? r.credits : "-"}
                              </td>
                              <td className="fw-bold border-0">
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      placeholder="Score"
                                      key={index}
                                      name="ects"
                                      value={r.ects}
                                      onChange={(e) =>
                                        details_handler(e, index)
                                      }
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </td>
                              <td className="border-0 fw-bold">
                                <Form.Group>
                                  <Form.Select
                                    defaultValue="PASS"
                                    name="grade"
                                    onChange={(e) => details_handler(e, index)}
                                  >
                                    <option value="PASS" key={1}>
                                      PASS
                                    </option>
                                    <option value="FAIL" key={2}>
                                      FAIL
                                    </option>
                                  </Form.Select>
                                </Form.Group>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="mb-3 fw-bold">
                    Total ECTS : {totalECTS}
                  </Col>
                </Row>
                <div className="mt-3">
                  {/* {birthday} */}
                  <Button
                    disabled={!saveValid}
                    onClick={() => saveCertificate()}
                    variant="primary"
                  >
                    Save All
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
