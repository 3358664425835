import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faExternalLinkAlt,
  faTimesCircle,
  faCheckCircle,
  faCalendarAlt,
  faCodeBranch,
  faShoppingCart,
  faFolder,
  faMapMarkedAlt,
  faPager,
  faFileCode,
  faDownload,
  faSearch,
  faUserGraduate,
  faPrint,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBootstrap,
  faGithub,
  faJs,
  faReact,
  faSass,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Table,
  Modal,
  Tooltip,
  OverlayTrigger,
  Form,
  Navbar,
  Nav,
  Badge,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Routes } from "../routes";
import ThemesbergLogo from "../assets/img/themesberg-logo.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";

import services from "../services/index";

import { CertificateManagementTable } from "../components/Tables";
import { ComponentToPrint } from "../components/printreports/Cetificates";

import edukLogoFull from "../assets/img/certificate/eduk_logo_full.svg";

export default () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [loading, setLoading] = useState(false);
  const [searchUsed, setSearchUsed] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [tableOptions, SetTableOptions] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedCertificateData, setSelectedCertificateData] = useState(null);

  const [popupType, setPopupType] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const handleClose = () => setShowPopUp(false);
  const modalOverlayClick = () => null;

  function handleChange(type, e) {
    switch (type) {
      case "searchtext":
        setSearchText(e.target.value);
        break;

      default:
        break;
    }
  }

  async function fetchData() {
    setSearchUsed(true);
    setRowData([]);
    setLoading(true);
    let params = { search: searchText };
    const req = await services.commonService.searchCertificate(params);
    setRowData(req?.data?.data ? req.data.data : []);
    setLoading(false);
  }

  async function printCertificate(ID) {
    await fetchCertificate(ID);
    handlePrint();
  }

  async function previewCertificate(ID) {
    await fetchCertificate(ID);
    setShowPopUp(true);
  }

  async function fetchCertificate(id) {
    const req = await services.commonService.publicCertificate(id);

    if (req?.data?.data?.certificate?.awarded_date) {
      var awardedDate = new Date(req.data.data.certificate.awarded_date);
      const awardedDateFormated = dateFormat(awardedDate, "d mmmm yyyy");
      
      var issueDate = new Date(req.data.data.certificate.issue_date);
      const issueDateFormated = dateFormat(issueDate, "d mmmm yyyy");
      
      req.data.data.certificate.awardedDateFormated = awardedDateFormated;
      req.data.data.certificate.issueDateFormated = issueDateFormated;

      let totCredits = 0;
      let totECTS = 0;
      let unitMarksArray = req.data.data.unitmarks;
      unitMarksArray.forEach((element) => {
        totCredits += element.ects && element.moduleunit?.credits
          ? element.moduleunit.credits
          : 0;
        totECTS += element.ects ? element.ects : 0;
      });

      req.data.data.certificate.total_credits = totCredits;
      req.data.data.certificate.total_ects = totECTS;
    }

    setSelectedCertificateData(req?.data?.data ? req.data.data : null);
  } 

  function LoadingSection(props) {
    let loading = props.loading ? props.loading : false;

    if (loading) {
      return (
        <div className="col-12 text-center">
          <div className="spinner-grow m-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return <></>;
  }

   

  const TableRow = (props) => {
    const { ID, certificate_number, course, student, awarded_date } = props;

    var awardedDate = new Date(awarded_date);
    const awardedDateFormated = dateFormat(awardedDate, "dS mmmm yyyy");


    return (
      <tr>
        <td>
          <span className="fw-normal">{"C" + certificate_number}</span>
        </td>
        <td>
          <span className="fw-normal">
            {student?.learner_id ? student.learner_id : ""}
          </span>
        </td>
        <td>
          <span className="fw-normal">{course?.name ? course.name : ""}</span>
        </td>
        <td>
          <span className="fw-normal">{awardedDateFormated}</span>
        </td>
        <td>
        <button
            className="btn btn-warning shadow-soft m-1"
            onClick={() => previewCertificate(ID)}
          >
            <span>
              Preview
            </span>
          </button>
          <button
            className="btn btn-primary shadow-soft m-1"
            disabled={searchText.length < 3}
            onClick={() => printCertificate(ID)}
          >
            <span>
              <FontAwesomeIcon icon={faPrint} className="me-1" /> Print
            </span>
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Navbar
        variant="light"
        expand="lg"
        bg="light"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={edukLogoFull} height={"70px"} style={{height : "70px !important"}}/>
            {/* <span className="ms-2 brand-text d-none d-md-inline">
              Volt React
            </span> */}
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            {/* <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#features">
                  Features
                </Nav.Link>
                <Nav.Link as={HashLink} to="#pages">
                  Pages
                </Nav.Link>
              </Nav>
            </Navbar.Collapse> */}
            <Button
              href="https://eduk.org.uk/"
              variant="outline-black"
              className="ms-3"
            >
              <FontAwesomeIcon icon={faHome} className="me-1" /> Home
            </Button>
          </div>
        </Container>
      </Navbar>
      <section
        className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12  text-dark"
        id="home"
      >
        <Container>
          <Row>
            <Col xs={12} md={8} className="mb-5 mb-lg-0 text-center mx-auto">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react1"></span>
              </div>
              <h1 className="fw-bolder text-dark">Certificate Verification</h1>
              <span className="h6 mb-3 d-block">
                Enter Student ID or Certificate Number
              </span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12 mb-2">
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUserGraduate} />
                      </InputGroup.Text>
                      <Form.Control
                        value={searchText}
                        onChange={(e) => handleChange("searchtext", e)}
                        autoFocus
                        required
                        type="text"
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12">
                    
                    <LoadingSection loading={loading} />

                    {!loading && (
                      <button
                        className="btn btn-primary shadow-soft btn-block"
                        data-loading-text="Sending"
                        disabled={searchText.length < 3}
                        onClick={() => fetchData()}
                      >
                        <span>Search Certificate</span>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </Col>

            <React.Fragment>
        {/* <Button variant="primary" className="my-3" onClick={() => setShowDefault(true)}>Default</Button> */}

        <Modal
          as={Modal.Dialog}
          centered
          show={showPopUp}
          size="xl"
          onHide={modalOverlayClick}
        >
          <Modal.Header>
            <Modal.Title className="h6">{popupType}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
          <div style={{ display: "block" }}>
              <ComponentToPrint
                data={selectedCertificateData}
                type={"preview"}
              />
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              I Got It
          </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
              Close
          </Button>
          </Modal.Footer> */}
        </Modal>
      </React.Fragment>

            <div style={{ display: "none" }}>
              <ComponentToPrint
                data={selectedCertificateData}
                ref={componentRef}
              />
            </div>

            {searchUsed && !loading && rowData.length === 0 && (
              <h5 className="text-center m-2">No Data Found !</h5>
            )}

            {rowData.length > 0 && (
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm mt-4"
              >
                <Card.Body className="pt-0">
                  <Table hover className="user-table align-items-center">
                    <thead>
                      <tr>
                        <th className="border-bottom">Certificate Number</th>
                        <th className="border-bottom">Learner ID</th>
                        <th className="border-bottom">Course</th>
                        <th className="border-bottom">Award Date</th>
                        <th className="border-bottom"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowData.map((t) => (
                        <TableRow key={`transaction-${t.ID}`} {...t} />
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            )}
          </Row>

          {/* <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg
              className="fill-soft"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3000 185.4"
            >
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure> */}
        </Container>
      </section>
      <footer className="footer    text-dark">
        <Container>
          <Row>
            <Col className="mb-md-0">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © EDUK Qualifications Limited 
                  <span className="current-year"> 2021</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
