
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCalendarAlt, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Nav, Image, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
}; 

/// ASHEN 
export const CertificateView = ({selectedCertificateData = {}, handlePrint}) => {  
  const [birthday, setBirthday] = useState("");
  useEffect(() => {  
    
    // console.log("hhehrhehheh");
  }, [])

  const TableRow = (props) => {
    const { moduleunit, ects, grade, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="fw-bold border-0">
          {moduleunit?.unitref ? moduleunit.unitref : "-"}
        </td>
        <td className="border-0">
          {moduleunit?.title ? moduleunit.title : "-"}
        </td>
        <td className="fw-bold border-0">
          {moduleunit?.level ? moduleunit.level : "-"}
        </td>
        <td className="border-0">
          {moduleunit?.credits ? moduleunit.credits : "-"}
        </td>
        <td className="fw-bold border-0">
          {ects ? ects : "-"}
        </td>
        <td className="border-0 fw-bold">
          {grade ? grade : "-"}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <button className="btn btn-primary btn-sm mb-1" onClick={handlePrint}>Print this out!</button>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h4 className="mb-2">Certificate Information</h4>
        <Form>
          <Row>
            <Col md={3} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label className="mb-0">CERTIFICATE NUMBER</Form.Label>
                {/* <Form.Control required type="text" placeholder="Enter your first name" /> */}
                <h5>{selectedCertificateData?.certificate?.certificate_number ? selectedCertificateData.certificate.certificate_number : ""}</h5>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">LEARNER ID</Form.Label>
                <h5>{selectedCertificateData?.certificate?.student?.learner_id ? selectedCertificateData.certificate.student.learner_id : ""}</h5>
              </Form.Group>
            </Col>            
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">COUNTRY</Form.Label>
                <h5>{selectedCertificateData?.certificate?.country?.country ? selectedCertificateData.certificate.country.country : ""}</h5>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">AWARD DATE</Form.Label>
                <h5>{selectedCertificateData?.certificate?.awardedDateFormated ? selectedCertificateData.certificate.awardedDateFormated : ""}</h5>
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">COURSE</Form.Label>
                <h5>{selectedCertificateData?.certificate?.course?.name ? selectedCertificateData.certificate.course.name : ""}</h5>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label className="mb-0">CENTRE</Form.Label>
                <h5>{selectedCertificateData?.certificate?.centre?.name ? selectedCertificateData.certificate.centre.name : ""}</h5>
              </Form.Group>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={12} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">NAME</Form.Label>
                <h5>{selectedCertificateData?.certificate?.student?.first_name ? selectedCertificateData.certificate.student.first_name : ""} {selectedCertificateData?.certificate?.student?.last_name ? selectedCertificateData.certificate.student.last_name : ""}</h5>
              </Form.Group>
            </Col>
          </Row>

          <h4 className="my-2">Unit Marks</h4>
          <Row>
            <Col sm={12} className="mb-3">
              <Table responsive  className="table-centered table-nowrap rounded mb-0">
                <thead className="">
                  <tr>
                    <th className="">Unit Ref</th>
                    <th className="">Unit Title</th>
                    <th className="">Level</th>
                    <th className="">Credits</th>
                    <th className="">ETCS</th>
                    <th className="">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCertificateData?.unitmarks ? selectedCertificateData.unitmarks.map(r => <TableRow key={`certipop-${r.ID}`} {...r} />) : <tr><td rowSpan={5}>NO DATA</td></tr>}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div> 
  );
};

export const CertificateAdd = ({rowdata = [], handlePrint}) => {  

  useEffect(() => {  
    
    // console.log("ADDDDDD");
  }, [])

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <h1>ADDD</h1>
      </Card.Body>
    </Card>
  );
};