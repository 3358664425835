import axios from "axios";
import config from "../config";

const client = axios.create(config.api);

// Request interceptor
client.interceptors.request.use(
  (request) => {
    if (!request.url.includes("/auth/login")) {
      request.headers.Authorization = "Bearer " + getLocalStoreToken();
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let url = error.request.responseURL;
    if (error.request.status === 401 && !url.includes("/auth/login")) {
      window.location = "#/sign-in";
    }
    return Promise.reject(error);
  }
);

const commonService = {
  searchCertificate(params) {
    return client.request({
      method: "get",
      url: "/common/search-certificate",
      params,      
    });
  },
  publicCertificate(id) {
    return client.request({
      method: "get",
      url: "/common/public-certificate/" + id
    });
  },
};

const courseService = {
  getCourses(params) {
    return client.request({
      method: "get",
      url: "/course",
      params,
    });
  },
};

const centreService = {
  getCentres(params) {
    return client.request({
      method: "get",
      url: "/centre",
      params,      
    });
  },
};

const studentService = {
  getStudents(params) {
    return client.request({
      method: "get",
      url: "/student",
      params,
    });
  },
  getStudentsByCentreID(params) {
    return client.request({
      method: "get",
      url: "/student/centre_id",
      params,
    });
  }
};

const certificateService = {
  getCertificates(params) {
    return client.request({
      method: "get",
      url: "/certificate",
      params,      
    });
  },
  getCertificate(id) {
    return client.request({
      method: "get",
      url: "/certificate/" + id
    });
  },
  postCertificateWithMarks(data) {
    return client.request({
      method: "post",
      url: "/certificate/postCertificateWithMarks",
      data: data,
    });
  },
};

const authService = {
  getAuth(params) {
    return client.request({
      method: "get",
      url: "/",
      params,
    });
  },
  postAuth(data) {
    return client.request({
      method: "post",
      url: "/auth/login",
      data: data,
    });
  },
};

function getLocalStoreToken() {
  let token = localStorage.getItem("token");
  return token;
}

export default {
  commonService,
  authService,
  courseService,
  centreService,
  studentService,
  certificateService,
};
